export function buildStatus(feature) {
    let div = document.createElement("div");
    div.id = "popup";
    let etat = feature.graphic.attributes.ETAT;
    let dmgPc = feature.graphic.attributes.Dmg_pc;
    let tooltipTxt = "";
    // Batiments rouges
    if (etat === "Sous-sol + premier plancher inonde") {
        tooltipTxt = "Selon les prévisions pour ce scénario :" +
            // "<li>Le bâtiment <span style='color:red'><b>est inondé</b></span> </li>" +
            "<li>Le sous-sol et le premier plancher <span style='color:red'><b>sont inondés</b></span></li>" +
            "<li>Le pourcentage de dommages au bâtiment est évalué à : <span style='color:red'><b>" + 100*dmgPc.toFixed(2) +"%</b></span></li>"
    }
    // Batiments oranges
    if (etat === "Sous-sol inonde" || etat === "Eau atteint batiment") {
        tooltipTxt = "Selon les prévisions pour ce scénario :" +
            // "<li>Le bâtiment <span style='color:red'><b>est inondé</b></span> </li>" +
            "<li>Le sous-sol <span style='color:red'><b>est inondé</b></span></li>" +
            "<li>Le pourcentage de dommages au bâtiment est évalué à : <span style='color:red'><b>" + 100*dmgPc.toFixed(2) +"%</b></span></li>"

    }
    // Batiments rose
    if (etat === "Isole (route inondee)") {
        tooltipTxt = "Selon les prévisions pour ce scénario :" +
            "<li>Le bâtiment <span style='color:green'><b>n'est pas inondé</b></span> </li>" +
            "<li>Le bâtiment <span style='color:red'><b>est isolé</b></span> (route inondée)</li>"
    }
    // Batiments vert
    if (etat === "Normal") {
        tooltipTxt = "Selon les prévisions pour ce scénario :" +
            "<li>Le bâtiment <span style='color:green'><b>n'est pas inondé</b></span> </li>"
    }
    div.innerHTML = tooltipTxt;
    return div;
}

export function streetStatus(feature) {
    let div = document.createElement("div");
    div.id = "popup";
    let status = feature.graphic.attributes.STATUS;
    let hauteurEau = feature.graphic.attributes.MAX_PROF;
    let longSegment = feature.graphic.attributes.Shape_Leng;
    let tooltipTxt = "";
    // Routes verte
    if (status === "1 - Non inonde") {
        tooltipTxt = "Selon les prévisions pour ce scénario :" +
            "<li>Ce tronçon de route <span style='color:green'><b>n'est pas inondé</b></span> (Praticable)</li>" +
            "<li>Longueur du segment : <b>" + longSegment.toFixed(2) + "</b> m</li>"
    }
    // Routes jaune
    if (status === "2 - Inonde - praticable") {
        tooltipTxt = "Selon les prévisions pour ce scénario :" +
            "<li>Ce tronçon de route <span style='color:red'><b>est inondé</b></span> (Praticable)</li>" +
            "<li>Profondeur d'eau maximale du segment : <span style='color:red'><b>" + hauteurEau.toFixed(2) +"</b></span> m</li>" +
            "<li>Longueur du segment : <b>" + longSegment.toFixed(2) + "</b> m</li>"
    }
    // Routes orange
    if (status === "3 - Inonde - Vehicules d'urgence seulement") {
        tooltipTxt = "Selon les prévisions pour ce scénario :" +
            "<li>Ce tronçon de route <span style='color:red'><b>est inondé</b></span> (Véhicule d'urgence seulement)</li>" +
            "<li>Profondeur d'eau maximale du segment : <span style='color:red'><b>" + hauteurEau.toFixed(2) +"</b></span> m</li>" +
            "<li>Longueur du segment : <b>" + longSegment.toFixed(2) + "</b> m</li>"
    }
    // Routes rouge
    if (status === "4 - Inonde - Impraticable") {
        tooltipTxt = "Selon les prévisions pour ce scénario :" +
            "<li>Ce tronçon de route <span style='color:red'><b>est inondé</b></span> (Impraticable)</li>" +
            "<li>Profondeur d'eau maximale du segment : <span style='color:red'><b>" + hauteurEau.toFixed(2) +"</b></span> m</li>" +
            "<li>Longueur du segment : <b>" + longSegment.toFixed(2) + "</b> m</li>"
    }

    div.innerHTML = tooltipTxt;
    return div;
}

export function bagStatus(feature) {
    let div = document.createElement("div");
    div.id = "popup";
    div.innerHTML = "Selon les prévisions pour ce scénario, cette digue requiert <span style='color:red'><b>" + feature.graphic.attributes.Nb_Sacs +
        "</b></span> sacs pour un total de <span style='color:red'><b>" + feature.graphic.attributes.Volume.toFixed(2) + "</b></span> m³ de sable.";

    return div;
}