import React, {Component} from 'react'
import Button from 'calcite-react/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHouseDamage, faRoad, faWater, faLayerGroup} from "@fortawesome/free-solid-svg-icons";
import {GiBrickWall} from 'react-icons/gi';

export class BtnToggle extends Component {
    togglehandler() {
        let x = document.getElementById("btnVisible");
        if (x.style.display === "block") {
            x.style.display = "none";
        } else {
            x.style.display = "block";
        }
    }
    render() {
        return (
            <button id="btnToggle" onClick={this.togglehandler}><FontAwesomeIcon icon={faLayerGroup}/></button>
        )
    }
}

class BtnToggleLayer extends Component {
    render() {
        return (
            <div id="btnVisible">
                <Button id="btnBuilding" data-title="Bâtiments"> <FontAwesomeIcon icon={faHouseDamage}/> </Button>
                <Button id="btnStreet" data-title="Routes"> <FontAwesomeIcon icon={faRoad}/> </Button>
                <Button id="btnWater" data-title="Hauteur d'eau"> <FontAwesomeIcon icon={faWater}/> </Button>
                <Button id="btnBag" data-title="Sacs de sable"> <GiBrickWall/> </Button>
            </div>
        )
    }
}

export default BtnToggleLayer;