// src/App.js
import React from "react";
import './index.css';
import {loadModules} from "esri-loader";
import {bagStatus, buildStatus, streetStatus} from "./utils/popup";
import {getStatsBuild} from "./utils/stats";
import {bagRenderer, buildRenderer, streetRenderer} from "./utils/renderer";
import Slider from "calcite-react/Slider";
import styled from 'styled-components';
import BtnToggleLayer from "./components/buttonVisible";
import {BtnToggle} from "./components/buttonVisible";
import LogoINRS from "./components/INRS-Logo-institutionnel_vertical.jpg";
import LogoMSP from "./components/MSP.jpg";
import LogoEC from "./components/EC.jpg";
// import Mention from "./components/mention"
// import LogoEnun from "./components/logo_enundation.jpg";
// import LogoGS from "./geosapiens.png";

class WebMapView extends React.Component {
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
    }

    componentDidMount() {
        // document.body.style.setProperty('background-color', '#b9cc8f'); //'#b9cc8f'
        document.body.style.setProperty('background-color', 'white'); //'#b9cc8f'
        // lazy load the required ArcGIS API for JavaScript modules and CSS
        // {css: "https://s3-us-west-1.amazonaws.com/patterns.esri.com/files/calcite-web/1.2.5/js/calcite-web.min.js"}
        loadModules(["esri/Map", "esri/views/MapView", "esri/layers/FeatureLayer", "esri/layers/TileLayer", "esri/widgets/BasemapToggle", "esri/widgets/Search", "esri/widgets/Expand", "esri/widgets/Legend", "esri/tasks/support/Query"], {css: true})
            .then(([Map, MapView, FeatureLayer, TileLayer, BasemapToggle, Search, Expand, Legend, Query]) => {
                //Number of scenarios
                // const nbScenario = 40;
                // const scenario = ['0047', '0099', '0195', '0230', '0266', '0302', '0337', '0385', '0422', '0458', '0506',
                //     '0542', '0590', '0638', '0686', '0734', '0782', '0830', '0889', '0937', '0997', '1057', '1116', '1176',
                //     '1236', '1307', '1367', '1439', '1510', '1594', '1665', '1748', '1831', '1914', '1997', '2092', '2173',
                //     '2266', '2355', '2447'];
                const nbScenario = 30;
                const scenario = ['0506',
                    '0542', '0590', '0638', '0686', '0734', '0782', '0830', '0889', '0937', '0997', '1057', '1116', '1176',
                    '1236', '1307', '1367', '1439', '1510', '1594', '1665', '1748', '1831', '1914', '1997', '2092', '2173',
                    '2266', '2355', '2447'];
                //Buildings URL
                const buildUrls = [];
                for (let i = 1; i < nbScenario + 1; i++) {
                    let urls = "https://services5.arcgis.com/mCb6CplkZg4pCYYJ/arcgis/rest/services/Batiments_SJSR/FeatureServer/";
                    buildUrls.push(urls.concat(i))
                }
                //Streets URL
                const streetUrls = [];
                for (let i = 1; i < nbScenario + 1; i++) {
                    let urls = "https://services5.arcgis.com/mCb6CplkZg4pCYYJ/arcgis/rest/services/Routes_SJSR/FeatureServer/";
                    streetUrls.push(urls.concat(i))
                }
                //Bags URL
                const bagUrls = [];
                for (let i = 1; i < nbScenario + 1; i++) {
                    let urls = "https://services5.arcgis.com/mCb6CplkZg4pCYYJ/arcgis/rest/services/SacsSable_SJSR/FeatureServer/";
                    bagUrls.push(urls.concat(i))
                }
                //Water heights URL
                const waterUrls = [];
                for (let i = 0; i < nbScenario; i++) {
                    let urls = "https://tiles.arcgis.com/tiles/mCb6CplkZg4pCYYJ/arcgis/rest/services/Plaine_Inondable_";
                    waterUrls.push(urls.concat(scenario[i], "m3s_tif/MapServer"))
                }
                //Set popup for buildings
                let buildPopup = {
                    title: "{ADRESSE}",
                    outFields: ["*"],
                    content: buildStatus
                };
                //Set popup for streets
                let streetPopup = {
                    title: "{NOMRUE_C_D}",
                    outFields: ["*"],
                    content: streetStatus
                };
                //Set popup for bags
                let bagPopup = {
                    title: "Digue",
                    outFields: ["*"],
                    content: bagStatus
                };
                //Create multiple instances of FeatureLayer for buildings
                let buildingLayer = buildUrls.map(function (buildUrl) {
                    return new FeatureLayer({
                        url: buildUrl,
                        title: "État des bâtiments",
                        popupTemplate: buildPopup,
                        renderer: buildRenderer
                    });
                });
                //Create multiple instances of FeatureLayer for streets
                let streetLayer = streetUrls.map(function (streetUrl) {
                    return new FeatureLayer({
                        url: streetUrl,
                        title: "État du réseau routier",
                        popupTemplate: streetPopup,
                        renderer: streetRenderer
                    });
                });
                //Create multiple instances of FeatureLayer for sand bags
                let bagLayer = bagUrls.map(function (bagUrl) {
                    return new FeatureLayer({
                        url: bagUrl,
                        title: "Sacs de sable",
                        popupTemplate: bagPopup,
                        renderer: bagRenderer
                    });
                });
                //Create multiple instances of TileLayer for water heights
                let waterLayer = waterUrls.map(function (waterUrl) {
                    return new TileLayer({
                        url: waterUrl,
                        title: "Hauteur d'eau"
                    });
                });
                //Set map
                const map = new Map({
                    basemap: 'hybrid',
                    layers: [waterLayer[20]]
                });
                //Set view
                this.view = new MapView({
                    container: this.mapRef.current,
                    map: map,
                    center: [-73.247665, 45.287665],
                    zoom: 14
                });
                //Set basemap
                let basemapToggle = new BasemapToggle({
                    view: this.view,
                    nextBasemap: "streets-navigation-vector"
                });
                this.view.ui.add(basemapToggle, "bottom-left");
                //Set search bar
                let search = new Search({
                    view: this.view
                });
                this.view.ui.add(search, "top-right");

                //Set the layer visibility
                // let div = document.createElement("div");
                // div.id = "layervisibility";
                // div.innerHTML = "<input type='checkbox' id='vehicle1' name='vehicle1' value='Bike'> <label htmlFor='vehicle1'> Bâtiments</label> ";
                // document
                //     .querySelector("#btnBag")
                //     .addEventListener("click", function (event)
                //

                // document.getElementById('vehicle1').onclick = function () {
                //     // access properties using this keyword
                //     if (this.checked) {
                //         map.add(buildingLayer[id]);
                //     } else {
                //         map.remove(buildingLayer[id]);
                //     }
                // };
                // document
                //     .querySelector("#vehicle1")
                //     .addEventListener("change", function (event) {
                //         if (this.checked) {
                //             map.add(buildingLayer[id]);
                //         } else {
                //             map.remove(buildingLayer[id]);
                //         }
                //     });

                //Set the Expand button for Legend and layer visibility button
                this.view.ui.add(
                    [
                        new Expand({
                            view: this.view,
                            content: new Legend({view: this.view}),
                            group: "top-left",
                            expanded: false,
                            expandTooltip: "Développer la légende",
                            expandIconClass: "esri-icon-maps"
                        })
                    ],
                    "top-left"
                );
                // this.view.ui.add(
                //     [
                //         new Expand({
                //             view: this.view,
                //             content: "<div>\n" +
                //                 "        <p id='mention'>Étendues des inondations basées sur les simulations hydrodynamiques à haute-densité par la Section hydrologie et écohydraulique, Services hydrologiques nationaux d’Environnement et Changement climatique Canada (2020)</p>\n" +
                //                 "    </div>",
                //             group: "top-left",
                //             expanded: false,
                //             expandTooltip: "Développer la légende",
                //             expandIconClass: "esri-icon-maps"
                //         })
                //     ],
                //     "top-left"
                // );

                let id = 20;


                getStatsBuild(buildingLayer, 20, Query); //Initialize statistics with the first view
                //Slider function
                document
                    .querySelector(".slider")
                    .addEventListener("mouseup", function (event) {
                        //Change view with slider
                        id = event.target.value;
                        if (id) {
                            map.removeAll();
                            map.add(waterLayer[id]);
                            // map.add(streetLayer[id]);
                            // map.add(buildingLayer[id]);
                            // map.add(bagLayer[id]);
                            getStatsBuild(buildingLayer, id, Query);
                            countBuilding = 0;
                            countRoad = 0;
                            countWater = 0;
                            countBag = 0;
                            document.getElementById("btnBuilding").style.color = "red";
                            document.getElementById("btnStreet").style.color = "red";
                            document.getElementById("btnWater").style.color = "white";
                            document.getElementById("btnBag").style.color = "red";
                        }
                    });
                //Button visible function
                let countBuilding = 0;
                document
                    .querySelector("#btnBuilding")
                    .addEventListener("click", function (event) {
                        if ((countBuilding % 2) === 0) {
                            map.add(buildingLayer[id]);
                            document.getElementById("btnBuilding").style.color = "white";
                            countBuilding += 1;
                        } else {
                            map.remove(buildingLayer[id]);
                            document.getElementById("btnBuilding").style.color = "red";
                            countBuilding += 1;
                        }
                    });
                let countRoad = 0;
                document
                    .querySelector("#btnStreet")
                    .addEventListener("click", function (event) {
                        if ((countRoad % 2) === 0) {
                            map.add(streetLayer[id]);
                            document.getElementById("btnStreet").style.color = "white";
                            countRoad += 1;
                        } else {
                            map.remove(streetLayer[id]);
                            document.getElementById("btnStreet").style.color = "red";
                            countRoad += 1;
                        }
                    });
                let countWater = 0;
                document
                    .querySelector("#btnWater")
                    .addEventListener("click", function (event) {
                        if ((countWater % 2) === 0) {
                            map.remove(waterLayer[id]);
                            document.getElementById("btnWater").style.color = "red";
                            countWater += 1;
                        } else {
                            map.add(waterLayer[id])
                            document.getElementById("btnWater").style.color = "white";
                            countWater += 1;
                        }
                    });
                let countBag = 0;
                document
                    .querySelector("#btnBag")
                    .addEventListener("click", function (event) {
                        if ((countBag % 2) === 0) {
                            map.add(bagLayer[id]);
                            document.getElementById("btnBag").style.color = "white";
                            countBag += 1;
                        } else {
                            map.remove(bagLayer[id])
                            document.getElementById("btnBag").style.color = "red";
                            countBag += 1;
                        }
                    });
            });
    }

    componentWillUnmount() {
        if (this.view) {
            // destroy the map view
            this.view.container = null;
        }
    }

    render() {
        return (
            <div>
                <div className="webmap" ref={this.mapRef}/>
                {/*<BtnToggleLayer/>*/}
            </div>
        )
    }
}

// const myRange = ['47', '99', '195', '230', '266', '302', '337', '385', '422', '458', '506',
//     '542', '590', '638', '686', '734', '782', '830', '889', '937', '997', '1057', '1116', '1176',
//     '1236', '1307', '1367', '1439', '1510', '1594', '1665', '1748', '1831', '1914', '1997', '2092', '2173',
//     '2266', '2355', '2447'];
const myRange = ['506',
    '542', '590', '638', '686', '734', '782', '830', '889', '937', '997', '1057', '1116', '1176',
    '1236', '1307', '1367', '1439', '1510', '1594', '1665', '1748', '1831', '1914', '1997', '2092', '2173',
    '2266', '2355', '2447'];

const SliderStyled = styled(Slider)`
  width: 1600px;
`;

class SliderStory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 20
        };
        this.updateSliderValue = this.updateSliderValue.bind(this)
    }


    updateSliderValue(event) {
        this.setState({value: parseInt(event.target.value, 10)})
    }

    render() {
        return (
            <div className="sliderstory">
                <div className="debit">{"Débit: " + myRange[this.state.value] + " m³/s"}</div>
                <div className="slider">
                    <SliderStyled
                        min={0}
                        max={29}
                        step={1}
                        value={this.state.value}
                        onChange={this.updateSliderValue}
                    />
                </div>
                {/*<div className={"logo"}><img src={Logo} alt={"logo"}/></div>*/}
            </div>
        )
    }
}

// const CardStyled = styled(Card)`
//     margin: 3px;
//     width: 124px;
//     height: 24%;
//     background-color: rgb(23, 18, 123);
//     @media (max-height: 650px) {
//         height: 10%;
//     }
// `;
//
// const CardContentStyle = styled(CardContent)`
//
// `;
//
// const CardTitleStyle = styled(CardTitle)`
//     font-size: 12px;
//     font-weight: bolder;
//     color: white;
//     text-align: center;
//     line-height:12px;
// `;

// const ButtonStyled = styled(Button)`
//     position: absolute;
//     left: 12px;
//     bottom: 5px;
//     width: 100px;
//     background-color: blue;
//     border-radius: 8px;
// `;

function StatsBlock(props) {
    return (
        <div className="statsBlock">
            <br/>
            <span className="nstats">{props.statisticName}</span>
            <br/>
            <span className="pstats" id={props.statistic}/>
            <br/>
            {/*<span className="pstats" id={props.statistic}/>*/}
            {/*<ButtonStyled>Histogramme</ButtonStyled>*/}
        </div>
    );
}


function StatsBlockContainer() {
    const tooltipMSP = "Ce projet a été réalisé en collaboration avec le Ministère de la sécurité publique du Québec";
    const tooltipEC = "Étendues des inondations basées sur les simulations hydrodynamiques à haute-densité par la" +
        " Section hydrologie et écohydraulique, Services hydrologiques nationaux d’Environnement et Changement" +
        " climatique Canada (2020)";
    return (
        <div className="statsBlockContainer">
            <StatsBlock statistic="statsIsole" statisticName="Bâtiment isolé"/>
            <StatsBlock statistic="statsEauAttBat" statisticName="Eau atteint le bâtiment"/>
            <StatsBlock statistic="statsSsInonde" statisticName="Sous-sol inondé"/>
            <StatsBlock statistic="statsPremInonde" statisticName="Premier plancher inondé"/>
            <img className={"logoMSP"} title={tooltipMSP} src={LogoMSP} alt={"logoMSP"}/>
            <img className={"logoEC"} title={tooltipEC} src={LogoEC} alt={"logoEC"}/>
        </div>
    );
}

function Logos() {
    return (
        <div className="logos">
            <img className={"logoINRS"} src={LogoINRS} alt={"logo"}/>
            {/*<img className={"logoEnun"} src={LogoEnun} alt={"logo"}/>*/}
        </div>
    );
}

function App() {
    return (
        <div className="App">
            <WebMapView/>
            <SliderStory/>
            <Logos/>
            <StatsBlockContainer/>
            {/*<DrawerStory/>*/}
            <BtnToggleLayer/>
            <BtnToggle/>
        </div>
    );
}

export default App;