//Function to get statistics for buildings
export function getStatsBuild(buildLayer, id, Query) {
    //Batiment isole
    const outputIsole = document.getElementById("statsIsole");
    const queryIsole = new Query();
    queryIsole.where = "ETAT = 'Isole (route inondee)'";
    buildLayer[id].queryFeatureCount(queryIsole).then(function (numResults) {
        // outputIsole.innerHTML = "Bâtiment isolé: <span style='color:red'>" + Number(numResults) + "</span>";
        outputIsole.innerHTML = Number(numResults);
    });
    //Eau atteint le batiment
    const outputEauAttBat = document.getElementById("statsEauAttBat");
    const queryEauAttBat = new Query();
    queryEauAttBat.where = "ETAT = 'Eau atteint batiment'";
    buildLayer[id].queryFeatureCount(queryEauAttBat).then(function (numResults) {
        // outputEauAttBat.innerHTML = "Eau atteint le bâtiment: <span style='color:red'>" + Number(numResults) + "</span>";
        outputEauAttBat.innerHTML = Number(numResults);
    });
    //Sous-sol inonde
    const outputSsInonde = document.getElementById("statsSsInonde");
    const querySsInonde = new Query();
    querySsInonde.where = "ETAT = 'Sous-sol inonde'";
    buildLayer[id].queryFeatureCount(querySsInonde).then(function (numResults) {
        // outputSsInonde.innerHTML = "Sous-sol inondé: <span style='color:red'>" + Number(numResults) + "</span>";
        outputSsInonde.innerHTML = Number(numResults);
    });
    //Premier plancher inonde
    const outputPremInonde = document.getElementById("statsPremInonde");
    const queryPremInonde = new Query();
    queryPremInonde.where = "ETAT = 'Sous-sol + premier plancher inonde'";
    buildLayer[id].queryFeatureCount(queryPremInonde).then(function (numResults) {
        // outputPremInonde.innerHTML = "Premier plancher inondé: <span style='color:red'>" + Number(numResults) + "</span>";
        outputPremInonde.innerHTML = Number(numResults);
    });
    //Nb personnes a evacuer
    // const sumEvac = {
    //     onStatisticField: "Nb_person",
    //     outStatisticFieldName: "Evac_sum",
    //     statisticType: "sum"
    // };
    // const outputNbEvac = document.getElementById("statsNbEvac");
    // const queryNbEvac = new Query();
    // queryNbEvac.where =
    //     "ETAT = 'Isolé (route inondée)' OR ETAT = 'Eau atteint le bâtiment' OR " +
    //     "ETAT = 'Sous-sol inondé' OR ETAT = 'Sous-sol et premier plancher inondé'";
    // queryNbEvac.outStatistics = [sumEvac];
    // buildLayer[id].queryFeatures(queryNbEvac).then(function (response) {
    //     const stats = response.features[0].attributes;
    //     outputNbEvac.innerHTML = stats.Evac_sum;
    // });
}