export const buildRenderer = {
    type: "unique-value",  // autocasts as new UniqueValueRenderer()
    field: "ETAT",
    defaultSymbol: {type: "simple-fill"},  // autocasts as new SimpleFillSymbol()
    uniqueValueInfos: [{
        value: "Normal",
        symbol: {
            type: "simple-fill",  // autocasts as new SimpleFillSymbol()
            color: "green",
            outline: {width: 0}
        }
    }, {
        value: "Isole (route inondee)",
        symbol: {
            type: "simple-fill",  // autocasts as new SimpleFillSymbol()
            color: "pink",
            outline: {width: 0}
        },
    }, {
        value: "Eau atteint batiment",
        symbol: {
            type: "simple-fill",  // autocasts as new SimpleFillSymbol()
            color: "yellow",
            outline: {width: 0}
        },
    }, {
        value: "Sous-sol inonde",
        symbol: {
            type: "simple-fill",  // autocasts as new SimpleFillSymbol()
            color: "orange",
            outline: {width: 0}
        },
    }, {
        value: "Premier plancher inonde (sans sous-sol)",
        symbol: {
            type: "simple-fill",  // autocasts as new SimpleFillSymbol()
            color: "red",
            outline: {width: 0}
        },
    }, {
        value: "Sous-sol + premier plancher inonde",
        symbol: {
            type: "simple-fill",  // autocasts as new SimpleFillSymbol()
            color: "red",
            outline: {width: 0}
        },
    }],
};

export const streetRenderer = {
    type: "unique-value",  // autocasts as new UniqueValueRenderer()
    field: "STATUS",
    defaultSymbol: {type: "simple-fill"},  // autocasts as new SimpleFillSymbol()
    uniqueValueInfos: [{
        value: "1 - Non inonde",
        symbol: {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "green",
            width: 3,
            style: "solid"
        }
    }, {
        value: "2 - Inonde - praticable",
        symbol: {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "yellow",
            width: 3,
            style: "solid"
        }
    }, {
        value: "3 - Inonde - Vehicules d'urgence seulement",
        symbol: {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "orange",
            width: 3,
            style: "solid"
        }
    }, {
        value: "4 - Inonde - Impraticable",
        symbol: {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "red",
            width: 3,
            style: "solid"
        }
    }],
};

export const bagRenderer = {
    type: "simple",  // autocasts as new UniqueValueRenderer()
    symbol: {
        type: "simple-line",  // autocasts as new SimpleMarkerSymbol()
        width: 4,
        color: "brown"
    }
};